import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import PageSection from '../../common/section'
import styled from 'styled-components'

import Img1 from '../../images/godkjent_av.png'

const Text = styled.div`
  flex: 1;
`

const CarOwnerOptionsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      dg: file(relativePath: { eq: "DG2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fasade: file(relativePath: { eq: "Fasade-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deler: file(relativePath: { eq: "slitedeler.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const description1 =
    'FRITT VERKSTEDVALG betyr at du fremdeles har full garanti - og reklamasjonsrett på bilen, enten du benytter et merkeverksted eller et frittstående verksted når du tar service.'
  return (
    <SubPageLayout title="Fritt verkstedvalg" description1={description1}>
      <SEO title="Fritt-verkstedvalg" />
      <Text>
        <PageSection
          //title="Som bileier har du fritt verkstedvalg"
          image={data.dg.childImageSharp.fluid}
        >
          <h4>FRITT VERKSTEDVALG = BILENS GARANTIER GJELDER!</h4>
          <p>
            Visste du at fritt verkstedvalg er en rettighet alle bileiere har? Det betyr at bilens
            nybilgaranti fremdeles gjelder som før om du velger et annet verksted enn der du kjøpte
            bilen. Når du velger en av de over 260 offentlig godkjente Bilxtra-bilverkstedene kan du
            være sikker på at alt arbeid utføres i henhold til bilens krav – og med nybilgarantien i
            behold.
          </p>
          <h4>3 KRAV SOM GJELDER – OG SOM Bilxtra OPPFYLLER</h4>
          <p>
            Det er tre viktige krav som må oppfylles for at full garanti- og reklamasjonsrett skal
            fortsette å gjelde.
          </p>
          <h4>1. Dokumentasjon på jobben og reservedeler</h4>
          <p>
            Velger du å bruke et frittstående verksted, må du huske å få dokumentasjon på jobben som
            er gjort og reservedelene som er brukt. Arbeidet skal være fagmessig utført og i henhold
            til bilprodusentens retningslinjer. Bildelene skal være av original kvalitet, og
            verkstedet må være offentlig godkjent.
          </p>
          <p>
            Spør gjerne verkstedet om de har kunnskap nok til å vedlikeholde og dokumentere arbeidet
            på bilen i henhold til garantien. Det er verkstedets ansvar å fremskaffe dette. Er denne
            dokumentasjonen i orden, så gjelder garantiene på bilen.
          </p>
          <h4>2. Deler av original kvalitet</h4>
          <p>
            Når bilprodusentene bygger biler, kommer alle delene fra ulike underleverandører.
            Bremsene kommer fra én produsent, lyddemperen fra en annen, lyskasteren fra en tredje og
            så videre. Bilprodusentens «originaldeler» eller «merkedeler» er i 80 – 85 % av
            tilfellene laget av andre. De samme produsentene leverer de samme delene til
            ettermarkedet. Det innebærer at du finner identiske originale deler eller deler av minst
            samme kvalitet hos de frittstående verkstedene – ofte til en lavere pris.
          </p>
          <p>Bilxtra benytter alltid deler av original kvalitet eller bedre.</p>
          <h4>3. Godkjent av Statens vegvesen</h4>
          <p style={{ margin: '0' }}>
            Pass på at verkstedet har dette skiltet «Godkjent av Statens Vegvesen» som du finner
            bilde av i denne artikkelen.
          </p>
          <p style={{ margin: '0', textAlign: 'center' }}>
            {/* <Img fluid={data.godkjent.childImageSharp.fluid} alt="Godkjent AV" /> */}
            <img alt="Godkjent" src={Img1} />
          </p>
          <p style={{ margin: '0' }}>
            I Norge er det Statens vegvesen som godkjenner bilverksteder. Godkjenningen reguleres av
            forskrift om kjøretøyverksted, fastsatt av Vegdirektoratet. Forskriften stiller strenge
            krav til både lokaler, teknisk utstyr, kompetanse og kvalitetsstyringssystem.
          </p>
          <p>
            Det er nøyaktig de samme kravene som stilles til både frie verksteder og
            merkeforhandlerens verksted. Alle Bilxtra verksteder er godkjent av Statens vegvesen.
          </p>
          <h4>FRITT VERKSTEDVALG SIDEN 2002</h4>
          <p>
            I 2002 ble det innført en forordning av EUs konkurransekommisjon, som hadde til hensikt
            å balansere makten i bilbransjen ved å bidra til økt konkurranse på like vilkår – og i
            praksis gi makten til deg som forbruker.
          </p>
          <p>
            Likevel er det fremdeles mange bileiere som ikke vet at de kan velge det bilverkstedet
            de ønsker å bruke uten å miste garantien. Mange opplever også usikkerhet fordi de ikke
            vet helt hvordan dette fungerer om det skulle være noe galt med bilen. Slik fungerer
            det:
          </p>
          <p>
            - Bilprodusent og merkeforhandler er ansvarlige for mangler som fantes da kjøretøyet ble
            solgt til kunde, og reklamasjonssaker, garantisaker eller tilbakekalling på grunn av
            feil kan kreves utført på merkeverksted.
            <br />- Verkstedet er ansvarlig for mangelfullt utført arbeid eller mangelfulle
            reservedeler som monteres i forbindelse med service.
          </p>
          <p>
            <br />
            <strong>
              Det er fritt verkstedvalg på alt av vanlig service og reparasjonsarbeid på bilen. I
              mange tilfeller vil et alternativ til merkeverksted være mest praktisk, det billigste
              alternativet eller den beste servicen.
            </strong>
          </p>
        </PageSection>
      </Text>
    </SubPageLayout>
  )
}

export default CarOwnerOptionsPage
